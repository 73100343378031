.footer_new {
  background: #17171f;
  position: relative;
}
.footer_inner_new {
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
}
.footer_new:before {
  content: "";
  background: linear-gradient(195.89deg, #1e2029 2.55%, #13141b 101.05%);
  width: 520px;
  height: 331px;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  position: absolute;
  left: 0;
  top: 0;
}
.cont_inner_text p span {
  display: block;
  font-weight: 600 !important;
  font-size: 14px;
}
.footer_logotext p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  margin: 20px 0 30px 0;
}

.footer_item h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.footer_item_ul {
  padding-left: 22px;
}

.footer_item_ul li {
  list-style: none;
  position: relative;
}

.footer_item_ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
}

.footer_item_ul li:before {
  content: "";
  position: absolute;
  background: url(../../assets/images/footer_li_before.png) no-repeat;
  width: 7px;
  height: 10px;
  left: -20px;
  top: 10px;
}

.cont_text_part {
  display: flex;
}
.address_label {
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 1rem;
}
.cont_text_icon {
  width: 33px;
  height: 33px;
  background: #262633;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33px;
}
.cont_inner_text {
  flex: 1 1;
  padding-left: 0.8rem;
}
.contact_item.row {
  margin-left: -5px;
  margin-right: -5px;
}
.contact_item [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.cont_inner_text p {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  margin: 0;
}
.cont_inner_text p {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
}

.custom-link {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  position: relative;
  z-index: 999;
  display: block;
  margin: 5px 0;
}

.cont_text_part {
  margin-bottom: 30px;
}

.cont_inner_text p a {
  color: #fff;
  font-size: 13px;
  display: block;
  margin: 5px 0;
  word-break: break-word;
  z-index: 0;
}
.footer_new:after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0;
  background: url(../../assets/images/footer_blur.png) no-repeat;
  /* filter: blur(286px); */
  width: 876px;
  height: 470px;
  z-index: 88;
  pointer-events: none;
}

.footer_bottom {
  border-top: 1px solid #272835;
  padding: 10px 0;
}

.footer_bottom_cont {
  display: flex;
  justify-content: space-between;
}

.footer_bottom_cont .copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
}

.privacy_y a {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  position: relative;
}
.privacy_y {
  color: white;
}
.footer_chat {
  width: 54px;
  height: 54px;
  right: 1.2rem;
  z-index: 99;
  position: fixed;
  bottom: 1.2rem;
}
.footer_chat img {
  max-width: 100%;
  height: auto;
}
.footer_item {
  position: relative;
  /* z-index: 1; */
}

@media screen and (max-width: 991px) {
  .footer_logotext {
    text-align: center;
    margin-bottom: 30px;
  }
  .footer_new:before {
    width: 100%;
  }
  .footer_bottom_cont {
    flex-wrap: wrap;
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .footer_item {
    text-align: center;
  }
  .footer_item_ul li:before {
    left: 25%;
  }
  .cont_inner_text {
    margin-left: 0px;
    max-width: 100%;
  }
  .cont_text_icon {
    display: none;
  }
}
