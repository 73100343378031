.text-muted {
  color: #2b8abf !important;
}
.control_field {
  height: 46px;
  background: #eef4f7;
  border-radius: 6px;
  border: 0px;
  margin-bottom: 2px;
}

.eq_names {
  background-color: #f7f8f9;
  border-radius: 5px;
}

.eq_names hr {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #aaaaaa;
  margin-top: 0;
  margin-bottom: 0;
}

.eq_names p {
  /* margin-top: 5px; */
  padding: 16px 15px;
  margin-bottom: -1px;
  line-height: 14px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #2f3939;
}
.submit_lead {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;
}
.eqp_name_fild {
  position: relative;
}
.eqp_lead_icon {
  position: absolute;
  right: 10px;
  top: 12px;
}

.radio_check [type="radio"]:checked,
.radio_check [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio_check [type="radio"]:checked + label,
.radio_check [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
  color: #2f3939;
  font-weight: 400;
  font-size: 14px;
}
.radio_check [type="radio"]:checked + label:before,
.radio_check [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  border: 2px solid #0acdfa;
  border-radius: 100%;
  background: transparent;
}
.radio_check [type="radio"]:not(:checked) + label:before {
  border: 2px solid #c5d7dd;
}
.radio_check [type="radio"]:checked + label:after,
.radio_check [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0acdfa;
  /* border: 3px solid #fff; */
  position: absolute;
  top: 7px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.radio_check [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio_check [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.radio_check {
  padding-left: 0px;
}
.radio_group {
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.information_area {
  background: #e6eded;
  border-radius: 5px;
  border: 0px;
}

.on_behalf {
  display: block;
  margin-bottom: 10px;
  margin-top: 25px;
}

.on_behalf input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.on_behalf label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #475c63;
}

.on_behalf label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 3px solid #015063;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 4px;
}

.on_behalf input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid #015063;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
