.expert_carousel {
  padding: 60px 0;
  background-color: #fff;
}
.car_box {
  padding: 20px;
  background: linear-gradient(to bottom, rgb(238 252 255) 0%, #f3fdff00 100%);
  border-radius: 0.5rem;
  margin: 1rem 0;
}
.ex_text {
  margin-top: 30px;
}

.ex_text h3 {
  font-weight: 600;
  font-size: 24px;
  color: #027994;
  margin-bottom: 15px;
}
.ex_text p {
  font-size: 14px;
  color: #455356;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
}

.send_inner_left {
  max-width: 30%;
  width: 100%;
  text-align: center;
}

.send_inner_right {
  max-width: 70%;
  width: 100%;
}

.send_inner_right h3 {
  font-size: 36px;
  line-height: 54px;
  font-weight: 700;
  color: #293538;
  margin-bottom: 10px;
}
.send_inner_right p {
  font-size: 14px;
  line-height: 1.4;
  color: #5d6566;
  margin: 0;
}

.exp_phone_input {
  height: 50px;
  border: 2px solid #293538;
  border-radius: 0.25rem;
}

.exp_phone_input .PhoneInputCountry {
  margin-left: 10px;
}

.send_messaage_form {
  padding: 3rem 0px 0;
}
.send_message_heading {
  margin-bottom: 3rem;
}
.send_message_inner_form .form-control {
  border: 2px solid #293538;
  background-color: transparent;
  min-height: 3.4rem;
}
.send_message_inner_form .form-label {
  font-size: 1rem;
  line-height: 1;
}
.send_message_heading h2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  text-transform: uppercase;
  color: #010101;
  position: relative;
  margin-bottom: 25px;
}
.request_call_inner {
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.6rem;
}
.message_send {
  background: #ffffff;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 5px;
}
.request_call {
  padding: 50px 0;
}
.about_inner_banner img {
  width: 100%;
}

.about_inner_banner h2 {
  text-align: center;
  padding: 50px 0 0 0;
}
@media screen and (max-width: 991px) {
  .carousel_inner {
    width: calc(100% / 3);
  }
  .about_inner_banner img {
    height: 200px;
    object-fit: cover;
  }
}
@media screen and (max-width: 767px) {
  .about_inner_banner img {
    height: 150px;
    object-fit: cover;
  }
  .about_inner_banner h2:before {
    width: 100%;
    height: 100%;
  }

  .about_inner_banner h2 {
    top: 50px;
    font-size: 35px;
  }
  .carousel_inner {
    width: calc(100% / 2);
  }
  .send_inner_message {
    padding-left: 30px;
    padding-right: 30px;
    flex-wrap: wrap;
  }
  .send_inner_left,
  .send_inner_right {
    max-width: 100%;
  }
  .send_inner_right h3 {
    font-size: 30px;
    line-height: 36px;
  }
  .send_message_inner_form {
    padding: 20px;
  }
}

@media screen and (max-width: 420px) {
  .carousel_inner {
    width: calc(100% / 1);
  }
}
