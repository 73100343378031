.eqp_ind {
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
  padding: 30px 20px;
}

.eqp_ind .select_box_rent {
  margin-top: 0px;
}

.dashboard_right_panel .eqp_ind .select_inner_box {
  margin-bottom: 0px;
  height: 40px;
  background: #e6eded url(../../assets/images/ind_arrower.png) 96% center
    no-repeat;
  appearance: none;
  border: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #2f3939;
}

.sel_industry label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
  margin-bottom: 5px;
}

.sel_industry {
  margin-bottom: 20px;
}
.cancel_button button {
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  padding: 0 25px;
  border: 0px;
  margin-right: 10px;
}
.button_group {
  display: flex;
}

.eqp_ind_or {
  width: 60%;
  background: #cbdce0;
  border-radius: 5px;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  margin: 40px auto;
}
.eqp_ind_or span {
  padding: 0 20px;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}

.addeq_vdeo_partts h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #000000;
}

.v_player {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .filter_partts.addeq_vdeo_partts {
    margin-top: 30px;
  }
}

/* TWO  */

.eqp_modelpart {
  display: flex;
  align-items: center;
}

.eqpmodel_left {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
}

.eqpmodel_left img {
  width: 100%;
  height: 100%;
}
.close_preview {
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #f00;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}

.close_preview img {
  width: auto !important;
  height: auto !important;
}

.preview_name {
  color: black;
  margin-bottom: 30px;
}

.after_upload_image.upload_preview {
  height: auto;
}
.after_upload_image.upload_preview img {
  height: auto;
}

.eqpmodel_right {
  margin-left: 30px;
}
.eqpmodel_right p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
}
.eqpmodel_right h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #2f3939;
  margin: 10px 0;
}

.ind_select {
  margin-top: 30px;
}

.ind_select h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3d474a;
}
.ind_rentresale ul li {
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #2f3939;
  margin-right: 30px;
}
.ind_rentresale ul {
  display: flex;
}
.add_eqp_partts_img p {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4e5e63;
}

.add_eqp_partts_img {
  margin: 30px 0;
}

.after_upload_image {
  width: 96px;
  height: 71px;
  background: #f6faf9;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #bbc8d1;
}

.after_upload_image img {
  width: 100%;
  height: 100%;
}

.uploadwrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploadwrapperbtn {
  background: #ffffff;
  border: 1px dashed #86a0a0;
  border-radius: 5px;
  width: 96px;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadwrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.add_images_partttts {
  display: flex;
  flex-wrap: wrap;
}
.set_details h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #2f3939;
  margin-bottom: 20px;
}
.innerdatetime {
  position: relative;
}

.innerdatetime .date_cal {
  position: absolute;
  right: 10px;
  top: 5px;
}

.date_picker {
  width: 100%;
  height: 36px;
  border-radius: 5px;
}

.range_rent_slider.hmr_slider label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
}
.range_rent_slider.hmr_slider {
  margin: 30px 0;
}

.byclicking ul li {
  list-style: none;
}

.byclicking label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #2f3939;
}

.save_for_later_submit {
  display: flex;
}
.main_doc {
  display: flex;
  justify-content: space-between;
}
.doc_field {
  max-width: 85%;
  width: 100%;
}

.doc_attach {
  background: #027994;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  margin-top: 30px;
  position: relative;
}

.doc_attach .custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 40px;
  height: 40px;
}

.check_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

@media screen and (max-width: 576px) {
  .eqp_modelpart {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .eqpmodel_right {
    margin: 20px 0;
    text-align: center;
    max-width: 100%;
    width: 100%;
  }
  .add_images_partttts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
