.popup-title {
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 600;
}
.popup-title-fullscreen {
  text-align: center;
  color: #febb0e;
  font-size: 18px;
  line-height: 26px;
  display: block;
  max-width: 90%;
  width: 100%;
  font-weight: 700;
  font-size: 26px;
}

.delete_style {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.delete_style button {
  margin-top: 20px !important;
  background: #0acdfa;
  border-radius: 5px;
  height: 44px;
  line-height: 30px;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  padding: 0 40px;
  border: 0px;
  align-items: flex-end;
}

.delete_style .yes {
  background: #df1919;
}
.delete_style .yes:hover {
  background: #fd0101;
}

.delete_style .no {
  background: #464646;
}
.delete_style .no:hover {
  background: #2a2a2a;
}

.delete_style .add_another {
  background: #f8c23b;
}
.delete_style .add_another:hover {
  background: #fcb603;
}

.delete_style .back_to_home {
  background: #0b8b9d;
}
.delete_style .back_to_home:hover {
  background: #0697b8;
}

@media (min-width: 992px) {
  .modal-lg {
    width: auto;
  }
}

@media (min-width: 768px) {
  .custom-modal.modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    height: auto;
    min-height: 100%;
    border-radius: 10px;
  }
}
