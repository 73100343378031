.tag_listing_inner_part {
  padding-right: 70px;
}
.tag_listing_inner_inner_part {
  background: #fff;
  padding: 30px;
  /* display: flex;
    justify-content: space-between; */
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
}
.tag_listing_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
}

.tag_listing_header h2 {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 20px;
  color: #000;
}
.tag_listing_body {
  padding: 20px 30px;
}

.tag_listing_inner_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tag_text {
  display: inline-block;
  border-radius: 30px;
  padding: 2px 7px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.tag_action {
  display: flex;
}

.tag_edit,
.tag_delete {
  margin: 0 5px;
  cursor: pointer;
}
.tag_field_text {
  height: 40px;
  background: #e6eded;
  border-radius: 5px;
  border: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #2f3939;
}

.tag_field_text:focus {
  background: #e6eded;
}

.select_text_color label {
  margin-bottom: 0px;
}
.preview_holder {
  border-radius: 30px;
  padding: 5px 20px;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
}
.submit_lead.lead_listing_submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global_tag input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.global_tag label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
font-size: 16px;
line-height: 18px;
color: #475C63;
}

.global_tag label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #5d6e72;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
}

.global_tag input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid #3a474a;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.global_tag input:checked + label:before {
  border-color: #3a474a;
}




@media screen and (max-width: 767px) {
  .tag_listing_inner_part {
    padding-right: 0px;
    margin-top: 30px;
  }
  .select_text_color {
    padding-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .tag_listing_inner_body {
    display: block;
    text-align: center;
  }
  .tag_action {
    justify-content: center;
    align-items: center;
  }
  .tag_listing_header {
    display: block;
    text-align: center;
  }
  .create_tag {
    margin-top: 10px;
  }
  .select_tag_color {
    flex-wrap: wrap;
  }
}
