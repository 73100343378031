.home_contact {
  padding-bottom: 0px;
  background: #fff !important;
}

.home_cont_inner {
  background: #ffffff;
  box-shadow: 8px 9px 34px rgba(25, 30, 32, 0.06);
  border-radius: 15px;
  padding: 50px 30px 0 30px;
  position: relative;
  z-index: 99;
  top: -99px;
}

.home_cont_inner_without_title {
  background: #ffffff;
  box-shadow: 8px 9px 34px rgba(25, 30, 32, 0.06);
  border-radius: 15px;
  padding: 25px 30px 0 30px;
  position: relative;
  /* z-index: 99; */
}

.contact_text.text-center .commn_heading h1:before {
  left: 50%;
  transform: translateX(-50%);
}

.without-title {
  padding-top: 50px;
}

.cont_bottom_part {
  margin-top: 70px;
}

.home_cont_left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home_cont_inner_inner {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(57, 156, 252, 0.14);
  border-radius: 15px;
  width: 100%;
  max-width: 265px;
  padding: 10px 10px 16px 10px;
  margin-bottom: 55px;
}

.home_cot_heading_part {
  display: flex;
}

.cont_left_box {
  width: 43px;
  height: 43px;
  border-radius: 10px 10px 10px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-top: -24px;
}

.cont_left_box.cont_1 {
  background: #fbffcc;
}

.cont_left_box.cont_2 {
  background: #fff8eb;
}

.cont_left_box.cont_3 {
  background: #f1effe;
}
.cont_left_box.cont_4 {
  background: #e8fbf8;
}
.cont_left_box.cont_5 {
  background: #f1effe;
}

.cont_right_text h3 {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #b0bf00;
}
.cont_right_text.service h3 {
  color: #af7200;
}
.cont_right_text.parts h3 {
  color: #5c48df;
}
.cont_right_text.jp h3 {
  color: #bb4baa;
}

.cont_right_text.buy h3 {
  color: #44beab;
}

.home_mail a,
.home_mail {
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #697f85;
  font-family: "Roboto", sans-serif !important;
}
.home_mail span {
  margin-right: 10px;
}

.home_c a {
  font-style: italic;
}

.home_cont_right .home_select {
  height: 55px;
  background: #eef4f7 url(../../assets/images/select_arrow.png) no-repeat 98%
    center;
  border-radius: 10px;
  appearance: none;
  border: 0px;
}

.home_cont_right {
  margin-top: -28px;
  padding-bottom: 20px;
}

.home_text_field {
  background: #eef4f6;
  border-radius: 10px;
  height: 55px;
  border: 0px;
}

.home_text_field:focus,
.home_textarea:focus {
  outline: 0px;
  box-shadow: none;
  background: #eef4f6;
}

.home_text_field::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #475c63;
  font-family: "Roboto", sans-serif !important;
}

.home_textarea {
  height: 99px !important;
  background: #eef4f6;
  border-radius: 10px;
  border: 0px;
}

.home_textarea::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #475c63;
  font-family: "Roboto", sans-serif !important;
}

.submit_form_home {
  height: 48px;
  background: #febb0e;
  border-radius: 5px;
  line-height: 48px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 50px;
  border: 0px;
  display: inline-block;
}

.conthome_heading {
  background: #232731;
  border-radius: 10px 10px 4px 10px;
  height: 56px;
  line-height: 56px;
  padding: 0 60px;
  display: inline-block;
  margin-top: -75px;
  position: absolute;
}
.conthome_heading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 56px;
}

.contactus_content {
  background: #edf0f2;
  padding: 4rem 0;
}
.contact_text .commn_heading{
  max-width: 515px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.contact_text .commn_heading h1{
  line-height: 36px;
  padding-top: 0px;
}
.contact_text p{
  font-size: 16px;
  line-height: 30px;
  max-width: 822px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #3B3D39;
}
.contact_text .commn_heading h1:before{
  content: none;
}
/*.contact_text {
  box-shadow: 8px 9px 34px rgb(25 30 32 / 6%);
  padding: 30px;
  width: 100%;
  border-radius: 10px;
}*/


.home_cont_left p{
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #3B3D39;
}
.commn_heading h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #000;
  position: relative;
  z-index: 2;
  padding-top: 30px;
}

.commn_heading h1:before {
  content: "";
  width: 70px;
  height: 3px;
  background: #febb0e;
  position: absolute;
  left: 0;
  top: 0;
}
.address_mail_field{
  margin-top: 40px;
}

.address_inner_mail_field{
  display: flex;
  margin-bottom: 25px;
}
.address_mail_icon{
  width: 43px;
  height: 43px;
  border-radius: 10px 10px 10px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address_mail_text{
  max-width: 80%;
  width: 100%;
  margin-left: 20px;
}

.address_mail_text h3{
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #4658F6;
}

.address_mail_text p, .address_mail_text p a{
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #697F85;
  margin-bottom: 0px;
  word-break: break-word;
}
.address_mail_icon.color_1212{
  background: #EBEDFF;
}

.address_mail_icon.color_1213{
  background: #FFEBFC;
}
.address_mail_icon.color_1214{
  background: #E8FBF8;
}
.address_mail_text.color___2 h3{
  color: #BB4BAA;
}

.address_mail_text.color___3 h3{
  color: #44BEAB;
}
@media screen and (max-width: 575px) {
  .cont_bottom_part {
    margin-bottom: 40px;
  }
  .commn_heading h1{
    text-align: center;
    font-size: 22px;
    line-height: 26px;
  }
  .commn_heading h1:before{
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 1199px) {
  .home_cont_inner {
    top: -80px;
  }
  .home_cont_inner_inner {
    max-width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .home_cont_inner {
    top: -18px;
  }
  .home_mail a,
  .home_mail {
    font-size: 11px;
  }
  .home_text_field {
    margin-bottom: 20px;
  }
  .submit_form_home {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    font-size: 14px;
  }
  /* .submit_form_home {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    font-size: 14px;
  } */

  .conthome_heading {
    height: 38px;
  }
  .conthome_heading h2 {
    line-height: 38px;
    font-size: 21px;
  }
}
