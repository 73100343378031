.projects_bannerer img{
    width: 100%;
    display: block;
}

.projects_bannerer{
    position: relative;
    z-index: -1;
}
.projects_bannerer:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(35, 43, 47, 0) 38.24%, #0B0B10 100%);
    height: 353px;
    z-index: 1;
}

.projets_banner_heading h2{
    position: absolute;
    z-index: 4;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 0px;
}


.proj_listing{
    padding: 75px 0;
    background: #E5E5E5 !important;
}

.prj_inner_listing{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
}

.prj_inner_inner_listing{
    padding: 0 18px;
    max-width: calc(100%/3);
    width: 100%;
    
}

.pjr_listing_part{
    /* background: #FFFFFF; */
    box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
    border-radius: 15px;
    height: 100%;
}

.prj_listing_image{
    width: 100%;
    height: 254px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.prj_listing_image:before{
    background: rgba(0,0,0,0.5);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.prj_listing_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prj_listing_text{
    padding: 30px;
    /* height: 100%; */
}
.prj_cont{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.prj_listing_image h2{
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #fff;
}

.prj_listing_text p{
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3B3D39;
    margin-bottom: 0px;
}
.prj_listing_image p{
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
}
.prj_inner_inner_listing{
    margin-bottom: 40px;
}

.showing_result_listing{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.showing_result_listing label{
    font-weight: 500;
font-size: 14px;
line-height: 16px;
color: #777777;
margin-bottom: 0px;
}

@media screen and (max-width:991px){
    .prj_inner_inner_listing{
        max-width: calc(100%/3);
        padding: 0 10px;
    }
}

@media screen and (max-width:767px){
    .projects_bannerer img{
        height: 220px;
        object-fit: cover;
    }
}

@media screen and (max-width:575px){
    .prj_inner_inner_listing {
        max-width: calc(100%/2);
        padding: 0 10px;
    }
    .prj_listing_image{
        height: 200px;
    }
    .proj_listing{
        padding: 25px 0;
    }
}
@media screen and (max-width:420px){
    .prj_inner_inner_listing {
        max-width: calc(100%/1);
        padding: 0 10px;
    }
}