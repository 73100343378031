.prj_listing_partts{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
    border-radius: 15px;
    margin-bottom: 25px;
}

.prj_listing_left{
    max-width: 20%;
    width: 100%;
    flex: 0 0 auto;
    overflow: hidden;
    border-radius: 15px 0 0 15px;
}
.prj_listing_left img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.prj_listing_right{
    max-width: 80%;
    width: 100%;
    flex: 0 0 auto;
}

.prj_inner_listing_right{
    padding: 20px 30px;
}

.prj_inner_listing_right h2{
    font-size: 22px;
    color: #000;
    line-height: 32px;
    margin-bottom: 10px;
}

.prj_inner_listing_right label{
    display: block;
    margin-bottom: 10px;
}

.prj_inner_listing_right p{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3B3D39;
    margin-bottom:10px;
}

.locat_create_at{
    display: flex;
    margin-top: 60px;
}
.locate_one{
    padding: 0 20px;
}
.locate_one label{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #4E5E63;
    
}
.locate_one .locate_time{
    padding-left: 10px;
    display: inline-block;
    margin-top: 0px;
}
.locate_one span{
    display: block;
    color: #000;
    margin-top: 10px;
}

@media screen and (max-width:767px){
    .prj_listing_left{
        max-width: 30%;
    }
    .prj_listing_right{
        max-width: 70%;
    }
}
@media screen and (max-width:575px){
    .prj_listing_left{
        max-width: 100%;
    }
    .prj_listing_right{
        max-width: 100%;
    }
    .prj_listing_partts{
        flex-wrap: wrap;
    }
    .locat_create_at{
        flex-wrap: wrap;
    }

    .prj_inner_listing_right h2{
        font-size: 18px;
    }

    .prj_listing_left{
        height: 150px;
        border-radius: 15px 15px 15px 15px;
    }
}