.header_loginand_email {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    padding-left: 3rem;
}
.new_customnav .navbar-brand{
    padding: 0px;
}
.new_customnav .custom_ul li a,
.new_customnav .custom_ul .nav_drop a {
    font-size: .95rem;
    font-weight: 500;
    color: #1F2B2E;
    position: relative;
    padding: 0;
}
.new_customnav .custom_ul li a:hover,
.new_customnav .custom_ul li a.active {
    color: #0acdfa;
}
.new_customnav .custom_ul li{
    margin-right: 25px;
}
.new_customnav .custom_ul li a.active:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: #0acdfa;
    left: 0;
    bottom: -28px;
    border-radius: 6px 6px 0 0;
}

.header_email a{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.header_logo_and_nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.expert_advice,
.login_butttton {    
    padding: 0.6rem;    
    border-radius: 0.4rem;
    color: #222a2c;
    font-size: .9rem;
    line-height: 1;
    display: inline-block;
    font-weight: 500;
    transition: all .3s ease;
}
.navbar-toggler {
    font-size: .9rem;
    padding: 0.3rem 0.4rem;
    line-height: 1;
}
.expert_advice {
    border: 2px solid #222a2c;
    background-color: #ffffff;    
}
.expert_advice,
.lang {
    margin-right: 1rem;
}
.expert_advice:hover {
    background-color: #222a2c;
    color: #fff;
}
.login_butttton {
    border: 2px solid #0acdfa;
    background-color: #0acdfa;
    color: #fff;
}
.login_butttton:hover {
    background-color: #fff;
    color: #0acdfa;
}
.after_signin {
    display: inline-block;
}

.new_customnav {
    background: #fff;
    position: fixed;
    z-index: 999;
    inset: 0 0 auto 0;
    height: 75px;
}
.whatsapp .phone_text span a{
    margin-bottom: 5px;
}
.after_signin .dropdown button{
    background: transparent !important;
    border: 0px;
    padding: 0px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* overflow: hidden; */
    position: relative;
}
.after_signin .dropdown button:before{
    content: "";
    position: absolute;
    background: url(../../assets/images/sign_up_border.png) no-repeat;
    width: 49px;
    height: 49px;
    left:-4px;
    top: -4px;
}
.after_signin .dropdown button img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
}
.after_signin .dropdown button:focus{
    outline: 0px;
    box-shadow: none !important;
}
.after_signin .dropdown button:after{
    content: none;
}
.after_signin .dropdown-menu {
    border-radius: 10px;
    width: 200px;
    border: 0;
    box-shadow: 0px 15px 20px rgb(23 33 40 / 11%);
    padding: 10px;
    right: 0 !important;
    left: unset;
}
.lang .dropdown-toggle:hover,
.lang .dropdown-toggle:focus {
    background-color: #e9ecef;
}
.after_signin .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 15px;
    color: #2A2E34;
    border-radius: 0.5rem;
}

.after_signin .dropdown-item span {
    margin-right: 10px;
}

.after_signin .dropdown-item:focus, 
.after_signin .dropdown-item:active, 
.after_signin .dropdown-item.active{
    background-color: #e9ecef;
}
.lang .dropdown-menu {
    border-radius: 10px;
    border: 0;
    box-shadow: 0px 5px 20px rgb(23 33 40 / 11%);
    padding: 10px;
}
.lang .dropdown-menu .dropdown-item {
    padding: 0.4rem;
    border-radius: 0.5rem;
    font-size: .9rem;
    color: #7a8389;
}
.lang .dropdown-menu .dropdown-item:active {
    background-color: #e9ecef;
}
.header_ph{
    margin-bottom: 5px;
}
.header_ph a .icon_w_holder, .header_email_inner a .icon_w_holder{
    width: 20px;
    margin-right: 8px;
    display: inline-block;
}

.header_ph a:hover, .header_email_inner a:hover{
    color: #FEBB0E;
}
.nav_drop a:after{
    border: none;
    background: url(../../assets/images/down_arrow.png) no-repeat;
    width: 10px;
    height: 7px;
    position: absolute;
    top: 10px;
}
.nav_drop .dropdown-menu {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    border: 0;
    box-shadow: 0px 5px 20px rgb(23 33 40 / 11%);
}
.phone_partts{
    display: flex;    
    align-items: center;
}
.phone_partts + .phone_partts {
    margin-top: 20px;
}

.phone_partts .phone_text{
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 17px;
    color: #a1aeb2;
    margin-left: 14px;
    font-family: 'Roboto', sans-serif !important;
}
.phone_partts .phone_image{
    min-width: 28px;
}
.new_customnav .phone_partts .phone_text span a{
    display: block;
    color: #1f2b2e;
    font-weight: 500;
    font-size: 0.813rem;
    line-height: 17px;
}

.new_customnav .phone_partts .phone_text span {
    display: block;
    color: #1f2b2e;
    font-weight: 500;
    font-size: 0.813rem;
    line-height: 17px;
    white-space: nowrap;
    font-family: 'Roboto', sans-serif !important;
}




/******Notification start*****************/
.my_message{
    display: flex;
}

.my_message_image{
    width: 40px;
    height: 40px;
}

.my_message_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.mesage_text{
    width: 80%;
    margin-left: 20px;
}

.mesage_text h3{
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.mesage_text p{
    font-size: 14px;
    color: #000;
    text-align: justify;
}
/******Notification End*****************/
@media screen and (max-width:1199px){
    .new_customnav .custom_ul li {
        margin-right: 0;
    }
}

@media screen and (max-width:991px){
    .navbar-collapse {
        position: fixed;
        right: 1rem;
        top: 75px;
        background-color: #fff;
        padding: 1rem;
        border-radius: 0.6rem;
        box-shadow: 0px 4px 70px rgba(17, 87, 103, 0.18);
        width: 280px;
        max-height: 340px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .new_customnav .custom_ul li a.active:before{
        display: none;
    }
    .header_loginand_email,
    .header_loginand_email > * {
        display: block !important;        
    }
    .lang,
    .expert_advice, 
    .login_butttton {
        text-align: center;
    }
    .expert_advice, .lang {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .header_loginand_email {
        padding-left: 0;
        margin-top: 1.5rem;
    }
    .lang .dropdown-toggle {
        background-color: #f7f7f7;
        width: 100%;
        display: block;
    }
    .nav_drop .dropdown-menu {
        padding: 10px;
        background-color: #f7f7f7;
        box-shadow: none;
    }
    .new_customnav .phone_partts .phone_text span a {
        max-width: 160px;
        word-break: break-all;
        white-space: normal;
    }
    .new_customnav .custom_ul .nav-item a {
        font-size: 1rem;
        display: block;
        padding: 0.6rem 0;
    }
    .nav_drop a:after {
        top: 20px;
    }
}

@media screen and (max-width:767px){
    .header_email{
        display: none;
    }
   
}

@media screen and (max-width:575px){
    .new_customnav .navbar-brand {
        max-width: 120px;
    }
}
