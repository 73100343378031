.mobile_login_inner_partts {
  position: relative;
  padding: 40px 0;
}

.mobile_logininner_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 8px 9px 34px rgba(25, 30, 32, 0.06);
  border-radius: 15px;
  padding: 20px 0;
}
.moblie_login_partts {
  position: relative;
}

.mobile_login_inner_partts:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  max-width: 50%;
  width: 100%;
  height: 100%;
  background: #0de2f0;
  padding: 60px 0;
}

.mobile_box_left {
  max-width: 50%;
  width: 100%;
  text-align: center;
}

.mobile_box_right {
  max-width: 50%;
  width: 100%;
}

.mobile_box_left h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}

.mobile_box_left p {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #6a6d75;
}

.login_boximg {
  margin-top: 40px;
}

.mobile_right_inner_box {
  max-width: 385px;
  width: 100%;
  margin: 0 auto;
}

.mobile_right_inner_box h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  /* text-transform: uppercase; */
  color: #232731;
  padding-top: 30px;
  padding-bottom: 30px;
}

.phone_field {
  height: 46px;
  background: #eef4f7;
  border-radius: 6px;
  /* margin-bottom: 30px; */
}

.phone_field .PhoneInputInput {
  background: transparent;
  border: 0px;
  padding: 0 20px;
}
.phone_field .PhoneInputInput:focus {
  border: 0px;
  box-shadow: none;
  outline: 0px;
}
.phone_field .PhoneInputCountry {
  padding-left: 10px;
  border-right: 1px solid #dae8ed;
  padding-right: 10px;
}
.login_box_partts .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
}

.login_submit_but {
  height: 46px;
  line-height: 46px;
  background: #febb0e;
  border-radius: 5px;
  width: 100%;
  padding: 0px;
  border: 0px;
  font-weight: 500;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
}

.login_or_partss {
  width: 60%;
  height: 1px;
  background: #cedce0;
  margin: 60px auto 0 auto;
  text-align: center;
  position: relative;
}
.login_or_partss span {
  padding: 0 20px;
  background: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  color: #4e5e63;
  background: #fff;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.login_using_email_id {
  text-align: center;
  margin-top: 30px;
}
.login_using_email_id a {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  /* color: #4e5e63; */
}

.dont_have_acount {
  margin-top: 70px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
}

.dont_have_acount a {
  font-weight: 600;
  /* color: #4e5e63; */
}
.otp_field {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp_field div {
  flex-wrap: wrap;
}

.otp_submit {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.error {
  border: 1px solid red !important;
}

.verify_no {
  color: #212529;
  font-size: 14px;
  text-align: center;
  display: block;
  margin-bottom: 20px;
}
.verify_no span {
  color: blue;
  cursor: pointer;
}
.countdown {
  margin-top: 25px;
  color: #cd5c5c;
  font-size: 15px;
}
.resend {
  color: #cd5c5c;
  font-size: 15px;
}
.resend span {
  cursor: pointer;
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 991px) {
  .mobile_right_inner_box {
    max-width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .mobile_box_left,
  .mobile_box_right {
    max-width: 100%;
  }
  .mobile_right_inner_box {
    max-width: 90%;
  }

  .mobile_right_inner_box h4{
    font-size: 20px;
  }
  .inputStyle{
    margin: 0 10px;
    width: 2.6rem !important;
    height: 2.6rem;
  }
  
}
