.notification_list .toast.hmc_toast {
  background-image: linear-gradient(to bottom, white 50%, transparent 100%);
  background-color: transparent;
  max-width: 100%;
  position: relative;
}

.notification_list .toast.hmc_toast:hover {
  box-shadow: 0 1px 2px rgb(31 37 40 / 17%);
}

.not_heading h4 {
  font-size: 1.1rem;
  color: #495962;
  margin-bottom: 0.5rem;
}
.not_heading h4 strong {
  color: #364a56;
  font-weight: 600;
}
.notification_list {
  padding-left: 1.5rem;
}
.not_body p {
  font-size: 0.9rem;
  color: #495a64;
}
.notification_date {
  font-size: 0.8rem;
  color: #676f74;
  margin-bottom: 0.3rem;
}
.not_body small {
  color: #0f88f1;
}
.hmc_toast.unread:before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #07db8c;
  position: absolute;
  left: -1.5rem;
  top: 1.8rem;
  border-radius: 50%;
}
.clear_noti.btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #ffffff;
  color: #ff4b4b;
  line-height: 1.4;
  z-index: 10;
  border-radius: 0.5rem;
}
.clear_noti.btn:hover {
  background-color: #ffecec;
}
.notification_pagination {
  display: flex;
  justify-content: center;
}
