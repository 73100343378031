.react_textfield {
  position: relative;
}

.text_111 {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 0 10px 0 5px;
  border-right: 1px solid #dae8ed;
}

.react_textfield .control_field {
  height: 46px;
  background: #eef4f7;
  border-radius: 6px;
  border: 0px;
  padding: 0 20px 0 60px;
}
.dont_have_acount.have_accnt {
  margin-top: 30px;
}

/*****************OtP Verification start*******************/

/*****************OtP Verification end*******************/
