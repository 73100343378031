.export_partts a{
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 10px 20px;
}
.export_partts{
    margin-left: 20px;
}
.export_partts a span{
    font-weight: 500;
    font-size: 14px;
    color: #000;
    display: inline-block;
    margin-left: 12px;
}

.man_details{
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.man_det_left{
    max-width: 75%;
    width: 100%;
    display: flex;
}

.man_det_right{
    max-width: 25%;
    width: 100%;
}

.mandet_inner_image{
    width: 128px;
    height: 128px;
    overflow: hidden;
    border-radius: 100%;
}

.mandet_inner_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.actiity_log{
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.activity_call_part a{
    width: 32px;
    height: 32px;
    background: #009621;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.activity_call_part{
    text-align: center;
}
.actiity_log{
    margin-top: 24px;
}
.activity_call_part span, .activity_log_part span{
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #595959;
    text-align: center;
    margin-top: 12px;
}

.activity_log_part a{
    width: 32px;
    height: 32px;
    background: #EB7F1E;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;margin: 0 auto;
}

.man_det_details{
    margin-left: 30px;
}

.man_det_details h2{
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000;
}

.man_det_details h2 span{
    padding: 5px 10px;
    display: inline-block;
    border-radius: 10px;
    margin-left: 20px;
}

.man_det_details h2 span.hmc_user{
    background: #FFF3D6;
    font-weight: 400;
    font-size: 13px;
    color: #027994;
}

.man_det_details p{
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #595959;
}

.eqp_dettailss{
    margin-top: 40px;
}
.eqp_dettailss p{
    margin-bottom: 10px;
}
.eqp_dettailss p label{
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #595959;
    width: 130px;
}

.eqp_dettailss p span{
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
}
.lead_creat_date{
    margin-bottom: 20px;
}
.lead_creat_date label{
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #595959;
    display: block;

}
.lead_creat_date .create_dateeew{
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    display: block;
}

.man_details{
    margin-top: 20px;
    border-radius: 15px;
}
.lead_stat .in_process{
    background: #FFF3B6;
    border-radius: 30px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #BD8800;
}

.lead_activity_heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lead_activity_heading h2{
    font-weight: 500;
    font-size: 24px;
    color: #000000;
}

.lead_act_partts{
    margin-top: 40px;
}

.assign_dis a{
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

.lead_ph{
    font-weight: 400;
    font-size: 14px;
    color: #2A2E34;
}
.lead_ph img{
    margin-right: 7px;
}

.lead_det_inner .lead_eq_inner span{
    width: auto;
    margin: 5px 0;
}

.lead_tooltip_img{
    margin-right: 7px;
}

.lead_eq_inner .progress{
    height: 4px;
    background: #D9D9D9;
    border-radius: 2px;
    margin-top: 15px;
}

.lead_eq_inner .progress .progress-bar{
    background: #2F8E6E;
}

.lead_eq_inner .lead_det_status{
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: #BD8800;
    background: #FFF3B6;
    border-radius: 30px;
    padding: 5px 10px;
    display: inline-block;
}

.de_arrow{
    margin-top: 20px;
}

.lead_det_inner .lead_equipment .lead_eq_inner{
    max-width: calc(100%/7);
    width: 100%;
}

.lead_det_inner .mylead_inner_inner_page{
    width: 140%;
}

.lead_status_activity{
    margin-top: 40px;
}

.lead_status_activity h2{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000;
    margin-bottom: 20px;
}

.lead_sta_act_innernn{
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
    border-radius: 15px;
    padding: 20px;
}

.lead_status_activity.lead_tabss .nav.nav-tabs{
    background: transparent;
    box-shadow: none;
}

.latest_files{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.latest_files_heading h3{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000;
    margin-bottom: 6px;
}

.latest_files_heading p{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4E5E63;
}

.view_all a{
    background: #F5F5F5;
    border-radius: 5px;
    padding: 12px 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;

    color: #0ACDFA;
}

.pdf_download{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pdf_left{
    display: flex;
}
.pdf_text{
    margin-left: 10px;
}
.pdf_text h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 4px;
}
.pdf_text p{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4E5E63;
    margin-bottom: 0px;
}

.pdf_right_box{
    background: #F5F5F5;
    border-radius: 5px;
    width: 24px;
    height: 24px;
}

.pdf_right .dropdown button{
    background: transparent;
    border: 0px;
}

.pdf_right .dropdown.show>.dropdown-toggle{
    background: transparent;
    outline: 0px;
    box-shadow: none;
}

.pdf_right .dropdown>.dropdown-toggle::after{
    content: none;
}

.latest_files{
    margin-bottom: 20px;
}

.pdf_download{
    margin-bottom: 20px;
}