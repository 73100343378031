@import url("https://fonts.googleapis.com/css2?family=Anton&family=Poppins:wght@400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
  background-color: #f4f7f9 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 75px;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  padding: 0;
  margin: 0;
}
.container {
  max-width: 1240px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ser_loc {
  position: relative;
  /* z-index: 10; */
}
.location_shape_img {
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  width: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.4rem;
}
.location_shape_img img {
  cursor: pointer;
}
.loaderClass {
  position: absolute;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 60%);
  z-index: 9;
}
.container {
  position: relative;
}
.btn.btn_black_outline {
  border: 1px solid #000;
  font-size: 1rem;
  padding: 0.8rem 2rem;
  line-height: 1;
  transition: all 0.3s ease;
}
.btn.btn_black_outline.btn-lg {
  padding: 1rem 3rem;
}
.btn.btn_primary.btn-sm {
  padding: 0.6rem 1rem;
}
.btn.btn_black_outline:not(:disabled):not(.disabled).active,
.btn.btn_black_outline:not(:disabled):not(.disabled):active,
.btn.btn_black_outline:hover,
.btn.btn_black_outline:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn.btn_primary {
  background: #0acdfa;
  border-radius: 5px;
  padding: 0.8rem 2rem;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  border-color: #0acdfa;
  line-height: 1;
  transition: all 0.3s ease;
}
.btn.btn_primary.btn-lg {
  padding: 1rem 3rem;
  font-size: 1rem;
}
.btn.btn_primary:not(:disabled):not(.disabled).active,
.btn.btn_primary:not(:disabled):not(.disabled):active,
.btn.btn_primary:hover,
.btn.btn_primary:focus {
  color: #fff;
  background-color: #027994;
  border-color: #027994;
}
.btn.btn_primary:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 119 147 / 13%);
}
.btn.btn_primary.btn-sm {
  padding: 0.6rem 1rem;
}
.btn.btn_black_outline:not(:disabled):not(.disabled).active,
.btn.btn_black_outline:not(:disabled):not(.disabled):active,
.btn.btn_black_outline:hover,
.btn.btn_black_outline:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn.btn_primary {
  background: #0acdfa;
  border-radius: 5px;
  padding: 0.8rem 2rem;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  border-color: #0acdfa;
  line-height: 1;
  transition: all 0.3s ease;
}
.btn.btn_primary.btn-lg {
  padding: 1rem 3rem;
  font-size: 1rem;
}
.btn.btn_primary:not(:disabled):not(.disabled).active,
.btn.btn_primary:not(:disabled):not(.disabled):active,
.btn.btn_primary:hover,
.btn.btn_primary:focus {
  color: #fff;
  background-color: #027994;
  border-color: #027994;
}
.btn.btn_primary:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 119 147 / 13%);
}
.btn.btn_primary.disabled,
.btn.btn_primary:disabled {
  background-color: #12cdf3;
  border-color: #17cbed;
}
.btn.btn_warning.btn-sm {
  padding: 0.6rem 1rem;
}
.btn.btn_warning {
  background: #febb0e;
  border-radius: 5px;
  padding: 0.8rem 2rem;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  border-color: #febb0e;
  line-height: 1;
  transition: all 0.3s ease;
}
.btn.btn_warning.btn-lg {
  padding: 1rem 3rem;
  font-size: 1rem;
}
.btn.btn_warning:not(:disabled):not(.disabled).active,
.btn.btn_warning:not(:disabled):not(.disabled):active,
.btn.btn_warning:hover,
.btn.btn_warning:focus {
  color: #fff;
  background-color: #c58f03;
  border-color: #c58f03;
}
.btn.btn_warning:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 119 147 / 13%);
}

.commn_button {
  background: #febb0e !important;
  /* box-shadow: 5px 5px 8px rgb(181 130 0 / 80%) !important; */
}

.commn_button:hover,
.commn_button:focus {
  background: #febb0e !important;
  box-shadow: 0px 0px 10px rgb(181 130 0 / 80%) !important;
}
.commn_button:disabled {
  background: #febb0e !important;
  box-shadow: 5px 5px 8px rgb(181 130 0 / 80%) !important;
}

.paragraph {
  text-align: justify;
}

.invalid_input {
  color: red !important;
  text-align: left !important;
}

.invalid_input a {
  color: red !important;
  text-decoration: underline;
}

.required {
  color: #f00;
}

._loading_overlay_overlay {
  position: fixed !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 9999 !important;
}
.ds_header {
  margin-bottom: 1.5rem;
}
.ds_header h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 0;
}
.ds_header h4 {
  font-size: 18px;
  color: #2f3939;
  margin-bottom: 15px;
}
.ds_header p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5e63;
}
.hmc_card.card {
  box-shadow: 0px 1px 2px rgb(64 42 22 / 14%);
  border: 0;
  border-radius: 0.8rem;
}
.hmc_card .card-header {
  background-color: transparent;
  border: 0;
  padding: 1rem 1.5rem;
}
.hmc_card .card-header .card-title {
  font-size: 1.2rem;
}

.spc_tab .card-header-tabs {
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
}
.spc_tab .tab_content {
  padding: 1.5rem;
}
.spc_tab .card-header-tabs .nav-item {
  border: 0;
  margin: 0;
  padding: 1.4rem 2rem 1.5rem;
  line-height: 1;
  color: #4e5e63;
  font-weight: 600;
  font-size: 1rem;
  background-color: transparent;
  position: relative;
  border-radius: 0;
}
.spc_tab .card-header-tabs .nav-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.15rem;
  background-color: #0acdfa;
  transition: transform 0.3s ease;
  transform: scaleX(0);
}
.spc_tab .card-header-tabs .nav-item.active:after {
  transform: scaleX(1);
}
.spc_tab .card-header-tabs .nav-item.active,
.spc_tab .card-header-tabs .nav-item:hover {
  color: #000;
}

.toast.hmc_toast .toast-header {
  background-color: transparent;
}
.toast.hmc_toast {
  border: 0;
  border-radius: 0.6rem;
  margin-bottom: 1.2rem;
  background-color: #fff;
  box-shadow: none;
}
.hmc_radio,
.hmc_check {
  margin-bottom: 0.5rem;
}
.radio-inline,
.check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.hmc_radio label,
.hmc_check label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #3b4952;
  margin: 0;
  font-size: 0.88rem;
}
.hmc_radio [type="radio"],
.hmc_check [type="checkbox"] {
  position: absolute;
  width: 0;
  height: 0;
}

.hmc_radio [type="radio"] ~ span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #91a9af;
  border-radius: 100%;
  background: #fff;
}
.hmc_radio [type="radio"] ~ span:after {
  content: "";
  background: #0acdfa;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
  opacity: 0;
  transform: scale(0);
}
.hmc_radio [type="radio"]:checked ~ span:before {
  border-color: #0acdfa;
}
.hmc_radio [type="radio"]:checked ~ span:after {
  opacity: 1;
  transform: scale(1);
}

.hmc_check [type="checkbox"] ~ span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  border: 1px solid #91a9af;
  background: #fff;
  border-radius: 0.3rem;
}
.hmc_check [type="checkbox"] ~ span:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 6px;
  top: 6px;
  left: 4px;
  transition: all 0.2s ease;
  opacity: 0;
  transform: rotate(0);
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 2px 2px;
}

.hmc_check [type="checkbox"]:checked ~ span:before {
  border-color: #0acdfa;
  background-color: #0acdfa;
}
.hmc_check [type="checkbox"]:checked ~ span:after {
  opacity: 1;
  transform: rotate(-45deg);
}

.ds_tab_pill .nav-tabs {
  border: 0;
  margin-bottom: 2rem;
  display: inline-flex;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 2.5rem;
}
.ds_tab_pill .nav-tabs .nav-item {
  border: 0;
  color: #798791;
  padding: 0.5rem 2rem;
  margin: 0;
  border-radius: 2rem;
  transition: all 0.3s ease;
}
.ds_tab_pill .nav-tabs .nav-item.active {
  background-color: #0acdfa;
  color: #fff;
}
.has_tool {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_box {
  position: relative;
}
.search_box .form-control {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgb(116 69 45 / 10%);
  border-radius: 36px;
  border: 0px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #6e838a;
  min-width: 260px;
}
.sb_icon {
  position: absolute;
  right: 5px;
  top: 50%;
  width: 2rem;
  height: 2rem;
  transform: translateY(-50%);
  text-align: center;
  border-radius: 50%;
  color: #535f68;
  line-height: 2rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.sb_icon:hover {
  background-color: #f4f7f9;
}
.react-multiple-carousel__arrow {
  z-index: 10 !important;
}
.pro-icon-wrapper .fa {
  font-size: 20px !important;
}
.material-icons {
  font-size: 20px !important;
}
.card_primary.card {
  background: #fff;
  box-shadow: 0px 1px 2px rgb(116 69 45 / 10%);
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
  border: 0;
}
.section_title_md {
  font-size: 2.4rem;
  font-weight: 600;
  color: #13333a;
}
.section_sub_title {
  font-size: 1.1rem;
  color: #364b50;
}