.buy_rent_viewbox span.active_box img {
  -webkit-filter: invert(50%); /* Safari/Chrome */
  filter: invert(50%);
}

.individual_partts {
  margin-top: 25px;
}

.service_prov_left {
  max-width: 25%;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
}
.service_prov_left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service_prov_right {
  max-width: 75%;
  width: 100%;
  padding: 0 20px;
}

.service_prv_right_top h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.location_partts {
  display: flex;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #595959;
}

.location_partts img {
  margin-right: 5px;
}

.service_prv_right_bottom {
  display: flex;
  justify-content: space-between;
}

.rating_service label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #595959;
  display: block;
  margin-bottom: 5px;
}

.rating_service h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.rating_service .rating_connect {
  background: #0acdfa;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.service_prv_right_bottom {
  margin-top: 20px;
}
.ser_exp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 5px 0;
  cursor: pointer;
}

.exp_inner_sec {
  background: #f1f8f7;
  border-radius: 5px;
  padding: 10px;
}

.slide_toggle {
  margin-bottom: 20px;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: #bed1cf;
  border-radius: 5px;
}
.styled-checkbox:hover + label:before {
  background: #0acdfa;
}
.styled-checkbox:focus + label:before {
  box-shadow: none;
}
.styled-checkbox:checked + label:before {
  background: #0acdfa;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 7px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
.result_inner.service_prove {
  position: relative;
}
.individual_tag {
  position: absolute;
  background: #fde09c;
  border-radius: 0px 10px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  right: 0;
  top: 0;
  font-size: 12px;
  text-align: center;
  color: #645733;
  font-weight: 500;
}
.showing_result {
  margin-bottom: 50px;
}
@media screen and (max-width: 1199px) {
  .result_inner.service_prove {
    flex-wrap: wrap;
  }
  .service_prov_left,
  .service_prov_right {
    max-width: 100%;
  }
  .service_prov_left {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 991px) {
  .service_prv_right_bottom {
    flex-wrap: wrap;
  }
  .rating_service {
    margin-top: 20px;
  }
  .service_prov_right {
    padding: 0 5px;
  }
  .rating_service .rating_connect {
    width: 100%;
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .rating_service {
    width: 100%;
    text-align: center;
  }
  .result_star {
    margin: 0 auto;
  }
}
