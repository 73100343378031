.inner_prdtsss h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #015063;
  margin-bottom: 30px;
}

.inner_prdtsss {
  padding: 60px 0;
}

.prd_part_top {
  width: 100%;
  height: 238px;
  background: #dcecf0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.prd_part_top:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 30px rgb(2 121 148 / 80%);
  bottom: 0px;
  width: 70%;
  height: 50%;
  border-radius: 100%;
  left: 15%;
  right: 15%;
}
.prd_inner_parts label {
  display: block;
}
.prd_inner_parts input[type="radio"] {
  display: none;
}
.prd_inner_parts input[type="radio"]:checked + .box img {
  filter: brightness(0) invert(1);
}
.prd_inner_parts input[type="radio"]:checked + .box {
  background: #027994;
  box-shadow: 0px 0px 25px #98e1f1;
}

.prd_inner_parts h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #015063;
  text-align: center;
  margin-top: 20px;
}

.prd_inner_parts {
  margin-bottom: 30px;
}
.next_button {
  display: flex;
  justify-content: flex-end;
}
.next_button .next_but {
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  background: #027994;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border: 0px;
  margin-right: 11px;
}


/* Product New Page start */
.find_machine_heading{
  display: flex;
  justify-content: space-between;
}
.find_machine_heading h2{
  font-weight: 600;
  font-size: 38px;
  line-height: 40px;
  text-transform: uppercase;
  color: #1F2B2E;
  margin-bottom: 30px;
}
.used_rent{
  width: 300px;
  height: 52px;
  background: #FFFFFF;
  border-radius: 70px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
}
.used_rent div{
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #1F2B2E;
  display: block;
  max-width: 50%;
  width: 100%;
  margin-bottom: 0px;
  line-height: 43px;
  cursor: pointer;
  border-radius: 70px;
}
.used_rent div:hover{
  background: #caf5ff;
}
.used_rent .rent_inner{
  transition: all 0.3s ease-in-out;
}
.used_rent .rent_inner.active{
  background: #0ACDFA;
  
  width: 100%;
  height: 100%;
  color: #fff;
  transition: all 0.3s ease-in-out;
}


.find_machine_inner h4{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4E5E63;
  margin-bottom: 20px;
}
.checkboxes{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.machine_holder{
  padding: 0 5px;
  width: calc(100%/7);
}

.checkboxes .check_label{
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.checkboxes .machine_holder input[type=checkbox]{
  display: none;
}

.checkboxes .machine_holder input[type=checkbox]:checked + .check_label{
  border: 1px solid #0ACDFA;
}

.checkboxes .machine_holder input[type=checkbox]:checked + .check_label:before{
  position: absolute;
  content: "";
  background: url(../../assets/images/check_right.png) no-repeat;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
}

.checkboxes .check_label span{
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: #4E5E63;
  display: block;
  margin-bottom: 0px;
}

.brand_label{
 display: flex;
  flex-wrap: wrap;
  margin: 30px -7px 20px;
}
.showing_view_more.find_but{
  border: 0px;
  padding: 0 50px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-transform: uppercase;
}
.brand_label label{
  padding: 0 7px;
  margin-bottom: 30px;
}

.brand_label label span{
  background: #FFFFFF;
  border-radius: 10px;
  padding:10px 20px;
  font-weight: 500;
  font-size: 14px;
  color: #4E5E63;
}

.brand_label label span img{
  margin-left: 12px;
  cursor: pointer;
}

.brand_select{
  /* margin-top: -7px; */
}

.brand_select select{
  /* background: #FFFFFF url(.././../assets/images/down_arrow.png) no-repeat 96% center; */
  border: 1px solid #B9CAD9;
  border-radius: 10px;
  width: 173px;
  height: 39px;
}

.brand_select select{
  font-weight: 500;
  font-size: 14px;
  color: #4E5E63;
}

.avalaible_matching{
  margin-top: 40px;
}

.rented_col{
  margin-bottom: 30px;
}

.avalaible_matching h2{
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4E5E63;
}

.avalaible_matching .rented_machine_inner{
  padding: 0 0;
}
.showing_result{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.showing_result p{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #777777;
  margin-bottom: 0px;
}

.showing_view_more{
  background: #FEBB0E;
  border-radius: 5px;
  height: 37px;
  line-height: 37px;
  padding: 0 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 37px;
  color: #FFFFFF;
}

.showing_view_more:hover{
  color: #fff;
}


.machine_heading_select{
  display: flex;
  align-items: center;
}

.select_counting{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FEBB0E;
  border-radius: 100%;
  color: #fff;
  font-size: 14px;
  line-height: 60px;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
.find_machine_inner .machine_heading_select{
  margin-bottom: 30px;
}
.find_machine_inner .machine_heading_select h4{
  margin-bottom: 0px;
}
/* Product New Page End */
@media screen and (max-width: 991px) {
  .prd_part_top {
    height: 190px;
  }
  .prd_inner_parts h3 {
    font-size: 14px;
  }
}

/* page 2 */

.inner_prd_2 {
  padding: 60px 0;
}

.prd_top_checkfield {
  display: flex;
}

.form_group {
  display: block;
  margin-right: 60px;
}

.form_group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form_group label {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  color: #027994;
}

.form_group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #027994;
  box-sizing: border-box;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 25px;
}

.form_group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form_group input:checked + label:before {
  background: #027994;
}

.prd_top_checkfield {
  margin-bottom: 50px;
}

.choose_a_machine h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #015063;
  margin-bottom: 40px;
}

.choose_machine_inner_part {
  width: calc(100% / 4);
  padding: 0 14px;
  margin-bottom: 50px;
  background: #fff;
  position: relative;
}
.choose_machine_inner_part:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 30px rgb(2 121 148 / 80%);
  bottom: 0px;
  width: 80%;
  height: 50%;
  border-radius: 100%;
  left: 10%;
  right: 10%;
}

.choose_machine_inner_part label {
  margin-bottom: 0px;
  display: block;
  height: 100%;
}
.choose_machine_inner {
  margin: 0 -14px;
  display: flex;
  flex-wrap: wrap;
}
.choose_inner_box {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  height: 100%;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
}

.excavator_bottommme {
  padding: 10px;
}
.machine_box {
  width: 100%;
  height: 150px;
  background: #dcecf0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.machine_upper_box label {
  display: block;
}
.machine_upper_box input[type="radio"] {
  display: none;
}
.machine_upper_box input[type="radio"]:checked + .machine_box {
  background: #c4c4c4;
  border: 2px solid #027994;
  box-shadow: 0px 0px 25px #98e1f1;
  border-radius: 5px;
}

.excavator_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 6px;
}
.exv_heading_bottom {
  display: flex;
  justify-content: space-between;
}
.exv_heading_bottom p {
  font-size: 12px;
  line-height: 18px;
  color: #027994;
  margin-bottom: 4px;
}

.exv_heading_bottom span {
  font-size: 12px;
  line-height: 18px;
  color: #eb7f1e;
  font-weight: 600;
}
.excavator_heading h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #015063;
  font-family: "Poppins", sans-serif;
}
.excavator_heading span {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  color: #eb7f1e;
}

.more_machine {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.moremachine_line {
  height: 1px;
  width: 100%;
  background: #c4c4c4;
  line-height: 40px;
}
.eqp_checklist {
  padding: 60px 0;
  display: flex;
}
.more_but_machine {
  position: absolute;
  right: 0;
  top: -11px;
  font-size: 14px;
  line-height: 21px;
  color: #027994;
  font-family: "Poppins", sans-serif;
  padding: 0 10px;
  background: #fff;
}

.or {
  font-size: 14px;
  line-height: 28px;
  font-family: "Poppins", sans-serif;
  color: #027994;
  padding: 0 50px;
}

@media screen and (max-width: 1199px) {
  .choose_machine_inner_part {
    width: calc(100% / 3);
  }
  .machine_box {
    height: 144px;
  }

  .machine_holder{
    width: calc(100%/4);
  }
}

@media screen and (max-width: 991px) {
  .machine_box {
    height: 101px;
  }
  .form_group {
    margin-right: 27px;
  }
  .form_group label {
    font-size: 11px;
  }
}

@media screen and (max-width: 767px) {
  .choose_machine_inner_part {
    width: calc(100% / 3);
  }
  .machine_box {
    height: 94px;
  }
  .excavator_heading h4,
  .excavator_heading span {
    font-size: 12px;
  }
  .machine_holder{
    width: calc(100%/3);
  }

  .find_machine_heading h2{
    font-size: 28px;
    line-height: 32px;
  }
}

@media screen and (max-width: 575px) {
  .choose_machine_inner_part {
    width: calc(100% / 2);
  }
  .prd_top_checkfield,
  .eqp_checklist {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .find_machine_heading{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .find_machine_heading h2 br{
    visibility: hidden;
  }
  .find_machine_heading{
    margin-bottom: 30px;
  }
  .select_brandds_inner label{
    margin-bottom: 30px;
  }
  .machine_holder{
    width: calc(100%/2);
  }
  .showing_result{
    display: block;
    text-align: center;
  }
  .showing_view_more{
    display: inline-block;
    margin-top: 20px;
  }
}

.find_button .find_but {
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  background: #027994;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border: 0px;
  display: inline-block;
}

.choose_machine_inner_part input[type="checkbox"] {
  display: none;
}
.choose_machine_inner_part input[type="checkbox"]:checked + .choose_inner_box {
  /* background: #c4c4c4; */
  border: 2px solid #027994;
  /* box-shadow: 0px 0px 25px #98e1f1; */
  border-radius: 5px;
}
.machine_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* product list */

.buy_machine {
  background: #c1d4d9;
  padding: 60px 0;
}

.machine_textts {
  text-align: center;
}

.machine_textts h2 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.machine_textts p {
  font-size: 14px;
  color: #014050;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.inner_looking_for {
  position: relative;
}

.inner_looking_for .abs_item {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.showing_result {
  margin-top: 48px;
}

.result_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.result_top p {
  color: #027994;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.result_right {
  display: flex;
  align-items: center;
}

.result_right .dropdown .dropdown-toggle,
.result_right span {
  background: transparent;
  color: #027994;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  border: 0px;
  padding: 0px;
}
.result_right .dropdown .dropdown-toggle {
  margin-left: 10px;
}
.result_right .dropdown .dropdown-toggle:focus {
  background: transparent;
  box-shadow: none;
  padding: 0px;
}
.sponsor_text {
  width: 100%;
}
.sponsored_inner_section {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background: #fff;
  padding: 11px;
}

.sponsor_name_text {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
a:hover {
  text-decoration: none;
}
.sponsor_name_text .name_left h2 a {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #eb7f1e;
  text-transform: uppercase;
}

.sponsor_name_text .name_left span {
  color: #015063;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.name_right .rent_f {
  background: #34a346;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
}

.name_right .resale_f {
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  background: #027994;
  padding: 0 10px;
  margin-left: 5px;
}

.name_right .brand_f {
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  background: #e1621a;
  padding: 0 10px;
  margin-left: 5px;
}

.name_right {
  display: flex;
  align-items: center;
}

.name_right .dropdown .dropdown-toggle,
.name_right .dropdown .dropdown-toggle:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.name_right .dropdown .dropdown-toggle::after {
  content: none;
}

.sponsr_text_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #c4c4c4;
}

.text_bottom_left {
  width: 73%;
}
.text_bottom_right {
  width: 20%;
}

.text_bottom_right p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #02404f;
}
.text_bottom_left p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.sponsor_text {
  padding-left: 30px;
}

.hands_up {
  display: flex;
  margin-top: 20px;
}
.text_bottom_left p {
  color: #015063;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.hands_up p a {
  margin-right: 120px;
  font-size: 12px;
  font-weight: 400;
  color: #027994;
}

.text_start_partts {
  display: block;
  margin-top: 20px;
  color: #027994;
  font-size: 14px;
}

.view_machine a {
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #015063;
}

.sponsor_text_under {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.under_text_left {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #015063;
  width: 40%;
}
.under_text_right {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text_user_icon {
  width: 40px;
  height: 40px;
  background: #98e1f1;
  border-radius: 100%;
  line-height: 40px;
  text-align: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}

.under_like a .likebox {
  width: 24px;
  height: 24px;
  background: #f1f1f1;
  text-align: center;
  line-height: 24px;
  color: #027994;
  border-radius: 100%;
  margin-right: 5px;
}

.under_like a .likebox i {
  color: #027994;
  font-size: 12px;
}

.under_like a {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #015063;
  align-items: center;
}

.chat_with_seller a {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #015063;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #015063;
  display: inline-block;
  font-family: "Poppins", sans-serif;
}

.sponsored_image {
  position: relative;
}
.sponsored_abs {
  position: absolute;
  top: -11px;
  left: -11px;
}

.sponsored_inner_section {
  margin-bottom: 15px;
}

.sponsored_section {
  margin-bottom: 200px;
}
.looking_one.looking_two {
  max-width: 100%;
  background: #ffffff;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.looking_one.looking_two .search_input_field {
  background-color: transparent;
}

.looking_one.looking_two .search_field:after {
  content: none;
}

.sponsor_text_under.under_sponsorrr {
  margin-top: 20px;
}

.sponsr_text_bottom.bootom_text_sponsor .text_bottom_left {
  margin-bottom: 20px;
}

.available_forring {
  display: flex;
  align-items: center;
}
.available_forring span {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #015063;
  font-family: "Poppins", sans-serif;
}

.available_forring .ava_brandd_new {
  background: #755297;
  border-radius: 5px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  padding: 0 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 30px;
}

.sponsored_image {
  width: 38%;
}
.sponsored_image img {
  width: 100%;
}
.machine_holder_inner label img {
  width: 100px;
  object-fit: cover;
  height: auto;
}
.machine_holder_inner{
  height: 100%;
}
.machine_holder{
  margin-bottom: 20px;
}

.brand_select{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.business_name_partts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  border-top: 1px solid #e8eeef;
  padding-top: 1rem;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.prf_iconn_partts {
  max-width: 15%;
  flex: 0 0 15%;
}
.prf_iconn_partts img {
  width: 39px;
  height: 39px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #e8eeef;
}
.prf_iconnn_nammme {
  flex: 0 0 85%;
  padding-left: 0.6rem;
  max-width: 85%;
}
.prf_iconnn_nammme > span {
  color: #3d484a;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.2rem;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.prod_id {
  margin-bottom: 0.3rem;
  font-size: .75rem;
  color: #6d7b85;
}
@media screen and (max-width: 1199px) {
  .text_bottom_right p {
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  .home2nav.right_nav li {
    align-items: flex-start;
  }
  .sponsored_inner_section {
    flex-wrap: wrap;
  }
  .sponsored_image {
    margin: 0 auto;
  }

  .sponsor_text {
    padding-left: 0px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .inner_looking_for .abs_item {
    position: static;
    transform: translateY(0);
    text-align: center;
  }
  .sponsor_name_text {
    display: block;
    text-align: center;
  }
  .name_right {
    justify-content: center;
  }
  .text_bottom_left,
  .text_bottom_right {
    width: 100%;
    text-align: center;
  }
  .sponsr_text_bottom {
    flex-wrap: wrap;
  }
  .hands_up {
    justify-content: center;
    align-items: center;
  }
  .hands_up p a {
    margin-right: 30px;
  }
  .under_text_left,
  .under_text_right {
    width: 100%;
    justify-content: center;
  }

  .sponsor_text_under {
    flex-wrap: wrap;
  }
  .result_top {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .looking_one.looking_two {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 575px) {
  .sponsored_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand_select{
    flex-wrap: wrap;
  }
  .brand_select .form-control{
    max-width: 100%;
    width: 100%;
  }
  .showing_view_more.find_but{
    width: 100%;
    display: block;
  }
}
@media screen and (max-width: 520px) {
  .result_top {
    display: block;
    text-align: center;
  }
  .result_right {
    justify-content: center;
  }

  .chat_with_seller span {
    display: none;
  }
  .chat_with_seller a {
    width: 40px;
  }
  .machine_textts h2 {
    font-size: 35px;
  }
  .buy_machine {
    padding: 20px 0;
  }

  .sponsored_section {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 480px) {
  .result_right {
    flex-wrap: wrap;
  }
}
.available_forring .ava_brandd_new.resale_but {
  background: #4590d5;
}

.available_forring .ava_brandd_new.rent_but {
  background: #3a8e38;
}
